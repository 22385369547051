import React from "react";
import { Link } from "react-router-dom";
import "../css/main.css";
const NavigationIndicator = ({ activeIndex, handleDotClick }) => {
  const renderDots = () => {
    const dots = [];
    const routes = [
      "/",
      "/knowledge",
      "/insights",
      "/decode",
      "/simplicity",
      "/empower",
    ];
    for (let i = 0; i < 6; i++) {
      dots.push(
        <Link
          key={i}
          to={routes[i]}
          className={`dot ${activeIndex === i ? "active" : ""}`}
          onClick={() => handleDotClick && handleDotClick(i)}
        >
          <div className="dot-content" />
        </Link>
      );
    }
    return dots;
  };

  return (
    <div className="justify-content-center indicator-container">
      {renderDots()}
    </div>
  );
};

export default NavigationIndicator;
