import React from "react";
import { Link } from "react-router-dom";
import "../../css/main.css";

const Footer = () => {
  return (
    <div id="footer-container">
      <div id="hyperlink-container">
        <Link to="/privacypolicy" className="hyperlink">
          Privacy Policy
        </Link>
        <Link to="/termsandconditions" className="hyperlink">
          Terms and Conditions
        </Link>
        <Link to="/eula" className="hyperlink">
          EULA
        </Link>
      </div>

      <footer>
        <div class="copyright">
          <p>Powered By Novatide Labs</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
