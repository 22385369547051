import React from "react";
import "../../css/main.css";
import NavigationIndicator from "../NavigationIndicator";

const Decode = () => {
  const handleDownload = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    let downloadLink;

    if (/android/.test(userAgent)) {
      downloadLink =
        "https://play.google.com/store/apps/details?id=com.ai_alpha_mobile_app&pcampaignid=web_share";
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
      downloadLink = "https://apps.apple.com/us/app/ai-alpha/id6473463625";
    } else {
      alert(
        "Device not supported. Download the app from App Store or Play Store."
      );
      return;
    }

    const anchor = document.createElement("a");
    anchor.href = downloadLink;
    anchor.target = "_blank";
    anchor.click();
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1 col-sm-12">
          <h1 className="text-center text-lg-left font-weight-bold text-white ">
            Decode the market
          </h1>
          <p className="text-center text-lg-left subText">
            Master fundamentals and conquer support and resistance with AI
            Alpha.
          </p>
          <div className="text-center text-lg-left">
            <button className="btn-orange" onClick={handleDownload}>
              Download App
            </button>
            <div className="social-buttons">
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
              >
                <img
                  src="static/images/instagramnew.png"
                  alt="Instagram"
                  className="socialLogo"
                />
              </a>
              <a
                href="https://x.com/aialphaai"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
              >
                <img
                  src="static/images/twitternew.png"
                  alt="Twitter"
                  className="socialLogo"
                />
              </a>
              <a
                href="https://www.tiktok.com/@aialphaai"
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
              >
                <img
                  src="static/images/tiktoknew.png"
                  alt="TikTok"
                  className="socialLogo"
                />
              </a>
            </div>
          </div>
          <div className="text-center text-lg-left position-relative animated-image-container-decode">
            <img
              src="static/images/Educate/decode1.png"
              alt="Decode"
              className="animated-image image-decode"
            />
            <img
              src="static/images/Educate/decode2.png"
              alt="Decode"
              className="animated-image image-decode2"
            />
            <img
              src="static/images/Educate/decode3.png"
              alt="Decode"
              className="animated-image image-decode3"
            />
            <img
              src="static/images/Educate/decode4.png"
              alt="Decode"
              className="animated-image image-decode4"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Decode;
